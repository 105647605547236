import constants from 'constants/liquid-templates'
import LiquidTemplate from 'api/liquid-template'

export const fetchLiquidTemplates = () => async (dispatch) => {
  dispatch({
    type: constants.FETCH_LIQUID_TEMPLATES_REQUEST
  })

  try {
    const response = await LiquidTemplate.fetchAll()

    dispatch({
      type: constants.FETCH_LIQUID_TEMPLATES_SUCCESS,
      liquidTemplates: response
    })
  } catch (error) {
    dispatch({
      type: constants.FETCH_LIQUID_TEMPLATES_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const updateLiquidTemplate = (templateId, key, value) => {
  return {
    type: constants.UPDATE_LIQUID_TEMPLATE,
    templateId,
    key,
    value
  }
}

export const saveLiquidTemplates = (liquidTemplates = []) => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_LIQUID_TEMPLATES_REQUEST
  })

  const templates = liquidTemplates
    ? liquidTemplates.map(template => new LiquidTemplate(template).save())
    : getState().liquidTemplates.liquidTemplates.map(template => new LiquidTemplate(template).save())

  try {
    await Promise.all(templates)

    dispatch({
      type: constants.SAVE_LIQUID_TEMPLATES_SUCCESS
    })
  } catch (error) {
    dispatch({
      type: constants.SAVE_LIQUID_TEMPLATES_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}
