import Model from '@itsfadnis/jsonapi-client'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'

class Theme extends Model {
  static _type = 'themes';

  static baseURL = '/theme';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.brandColor = args.brandColor || null
    this.template = args.template || ''
    this.color1 = args.color1 || ''
    this.color2 = args.color2 || ''
    this.color3 = args.color3 || ''
    this.color4 = args.color4 || ''
    this.color5 = args.color5 || ''
    this.color6 = args.color6 || ''
    this.color7 = args.color7 || ''
    this.color8 = args.color8 || ''
    this.color9 = args.color9 || ''
    this.titleFont = args.titleFont || ''
    this.contentFont = args.contentFont || ''
  }

  _update () {
    return this.constructor.adapter
      .patch(this.constructBaseURL(), this.serialize())
      .then(({ data }) => this.constructor.deserialize(data))
      .catch((err) => {
        this.errors = new Errors(err.data)
        throw err
      })
  }
}

export default Theme
