import Model from '@itsfadnis/jsonapi-client'
import Errors from '@itsfadnis/jsonapi-client/dist/jsonapi-error'

class GoogleTranslateIntegration extends Model {
  static _type = 'google-translate-integrations';

  static baseURL = '/google_translate_integration';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.siteId = args.siteId
    this.position = args.position
    this.includedLanguages = args.includedLanguages
  }

  _update () {
    return this.constructor.adapter
      .patch(this.constructBaseURL(), this.serialize())
      .then(({ data }) => this.constructor.deserialize(data))
      .catch((err) => {
        this.errors = new Errors(err.data)
        throw err
      })
  }
}

export default GoogleTranslateIntegration
