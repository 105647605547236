import constants from '../constants/project-tag'
import ProjectTag from 'api/project-tag'
import { setup } from 'lib/adapter'

export const fetchProjectTags = () => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_PROJECT_TAGS_REQUEST
  })
  try {
    if (typeof window !== 'object') {
      setup(getState().currentUser.adapter)
    }

    const tags = await ProjectTag.query({ include_unused: 1 })
    dispatch({
      type: constants.FETCH_PROJECT_TAGS_SUCCESS,
      tags
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_PROJECT_TAGS_FAILURE,
      errorMessage: response.message || response.statusText || response.status
    })
  }
}
