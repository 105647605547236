import Model from '@itsfadnis/jsonapi-client'

class ProjectTag extends Model {
  static baseURL = '/project_tags';

  static _type = 'project-tags';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.name = args.name
  }
}

export default ProjectTag
