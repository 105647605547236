import Model from '@itsfadnis/jsonapi-client'

class LiquidTemplate extends Model {
  static _type = 'liquid-templates';

  static baseURL = '/liquid_templates';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.path = args.path
    this.body = args.body
    this.colorPalette = args.colorPalette
  }
}

export default LiquidTemplate
