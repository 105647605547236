const verifySiteToken = async (ctx) => {
  const host = `https://${ctx.req.headers.host}`;
  const url = `${host}/shared_site/verify`;

  const response = await fetchWithCookie(ctx, url);

  return response;
};

export const setSiteToken = async (ctx, token) => {
  const host = `https://${ctx.req.headers.host}`;
  const url = `${host}/shared_site/${token}?draft=true`;

  const response = await fetch(url);

  return response;
};

const fetchWithCookie = async (ctx, url) => {
  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      cookie: ctx.req.headers.cookie,
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  return response;
};

export default verifySiteToken;
