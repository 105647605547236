import Model from '@itsfadnis/jsonapi-client'

class Layout extends Model {
  static _type = 'layouts';

  static baseURL = '/layouts';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.selectedColorPalette = args.selectedColorPalette || ''
    this.colorMapping = args.colorMapping || {}
  }

  reset () {
    return this.constructor.adapter
      .patch(
        this.constructBaseURL() + '/' + this.id + '/reset_theme',
        this.serialize()
      )
      .then(({ data }) => this.constructor.deserialize(data))
  }

  resetColor () {
    return this.constructor.adapter
      .patch(
        this.constructBaseURL() + '/' + this.id + '/reset_color_mapping',
        this.serialize()
      )
      .then(({ data }) => this.constructor.deserialize(data))
  }
}

export default Layout
