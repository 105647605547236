import constants from 'constants/google-translate-integration'
import GoogleTranslateIntegration from 'api/google-translate-integration'

export const fetchGoogleTranslateIntegration = () => async dispatch => {
  dispatch({
    type: constants.FETCH_GOOGLE_TRANSLATE_INTEGRATION_REQUEST
  })

  try {
    const googleTranslateIntegration = await GoogleTranslateIntegration.fetch('')

    dispatch({
      type: constants.FETCH_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS,
      googleTranslateIntegration: { id: googleTranslateIntegration.id, ...googleTranslateIntegration.attributes() }
    })
  } catch (error) {
    dispatch({
      type: constants.FETCH_GOOGLE_TRANSLATE_INTEGRATION_FAILURE,
      errorMessage: error.statusText || error.status
    })
  }
}

export const updateGoogleTranslateIntegration = (key, value) => {
  return {
    type: constants.UPDATE_GOOGLE_TRANSLATE_INTEGRATION,
    key,
    value
  }
}

export const saveGoogleTranslateIntegration = () => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_GOOGLE_TRANSLATE_INTEGRATION_REQUEST
  })

  const googleTranslateIntegration = new GoogleTranslateIntegration(getState().googleTranslateIntegration.googleTranslateIntegration)

  try {
    await googleTranslateIntegration.save()
    dispatch({
      type: constants.SAVE_GOOGLE_TRANSLATE_INTEGRATION_SUCCESS
    })
  } catch (response) {
    dispatch({
      type: constants.SAVE_GOOGLE_TRANSLATE_INTEGRATION_FAILURE,
      errorMessage: response.statusText || response.status || 'Unprocessable Entity',
      errors: googleTranslateIntegration.errors.extract()
    })
  }
}

export const resetGoogleTranslateIntegration = () => {
  return {
    type: constants.RESET_GOOGLE_TRANSLATE_INTEGRATION
  }
}
